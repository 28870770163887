<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <template v-slot:coverImgId>
        <div v-if="!fileSrc" class="upload-no-img" @click="$refs.uploadfile.click()">+</div>
        <img v-else :src="fileSrc" class="upload-has-img" @click="$refs.uploadfile.click()"/>
        <input type="file" style="display: none;" ref="uploadfile" @change="uploadFile"/>
      </template>
      <template v-slot:middleElements>
        <div v-for="(item, index) in formData.middleElements" class="element-item" :key="index">
          <div class="field">
            <div class="field-item" v-for="keyItem in Object.keys(item)" :key="keyItem">
              <el-input :value="keyItem" />
              <template v-if="Object.keys(elementSelectConfig).includes(keyItem)">
                <el-select v-model="item[keyItem]" placeholder="请选择">
                  <el-option
                      v-for="configItem in elementSelectConfig[keyItem]"
                      :key="configItem.value"
                      :label="configItem.label"
                      :value="configItem.value">
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <el-input v-model="item[keyItem]" :placeholder="'value'" />
              </template>
            </div>
          </div>
          <div class="opt">
            <i v-if="cmd !== 'view'" class="el-icon-plus" @click.stop="addMiddleElement"/>
            <i v-if="cmd !== 'view'" class="el-icon-delete" @click.stop="delMiddleElement(index)"/>
          </div>
        </div>
      </template>
      <template v-slot:bottomElements>
        <div v-for="(item, index) in formData.bottomElements" class="element-item" :key="index">
          <div class="field">
            <div class="field-item" v-for="keyItem in Object.keys(item)" :key="keyItem">
              <el-input :value="keyItem" />
              <template v-if="Object.keys(elementSelectConfig).includes(keyItem)">
                <el-select v-model="item[keyItem]" placeholder="请选择">
                  <el-option
                      v-for="configItem in elementSelectConfig[keyItem]"
                      :key="configItem.value"
                      :label="configItem.label"
                      :value="configItem.value">
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <el-input v-model="item[keyItem]" :placeholder="'value'" />
              </template>
            </div>
          </div>
          <div class="opt">
            <i v-if="cmd !== 'view'" class="el-icon-plus" @click.stop="addBottomElement"/>
            <i v-if="cmd !== 'view'" class="el-icon-delete" @click.stop="delBottomElement(index)"/>
          </div>
        </div>
      </template>
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="true"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ $util.getTreeNames(row.accountGroup, 'name') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        title: '请输入标题内容',
        coverImgId: null,
        templateType: null,
        stepName: null,
        middleElements: [
          {
            content: '请输入文本内容',
            form: 'VIEW',
            isMain: 'NO',
            type: 'TXT',
          },
        ],
        bottomElements: [
          {
            content: '选项',
            form: 'BUTTON',
            isMain: 'NO',
            type: 'MORE',
          },
        ],
      },
      elementSelectConfig: {
        form: [],
        isMain: [
          {
            value: 'NO',
            label: 'NO',
          },
          {
            value: 'YES',
            label: 'YES',
          },
        ],
        type: []
      },
      config: [
        // lang:模板名称
        {
          label: this.$t('templateManage.stepName'),
          prop: 'stepName',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        // lang:模板名称
        {
          label: this.$t('templateManage.serialNumber'),
          prop: 'serialNumber',
          hidden: false,
          rules: {
            noNull: false,
          }
        },
        {
          tag: 'el-select',
          label: this.$t('templateManage.stepGroup'),
          prop: 'dictGroupId',
          hidden: false,
          rules: {
            noNull: false,
          },
          tagProps: {
            options: [],
            // props: {
            //   label: 'value',
            //   value: 'id',
            // },
          },
        },
        // lang:模板标题
        {
          label: this.$t('templateManage.templateTitle'),
          prop: 'title',
          hidden: false,
          rules: {
            noNull: false,
          }
        },
        // lang:模板类型枚举
        {
          tag: 'el-select',
          label: this.$t('templateManage.templateType'),
          prop: 'templateType',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          }
        },
        // lang:模板图标
        {
          tag: 'slot-content',
          label: this.$t('templateManage.coverImg'),
          prop: 'coverImgId',
          rules: {
            noNull: true,
          },
        },
        // lang:中部元素
        {
          tag: 'slot-content',
          label: this.$t('templateManage.middleElements'),
          prop: 'middleElements',
          rules: {
            noNull: true,
          },
        },
        // lang:底部元素
        {
          tag: 'slot-content',
          label: this.$t('templateManage.bottomElements'),
          prop: 'bottomElements',
          rules: {
            noNull: true,
          },
        },
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      workFlowStepTemplateTypeEnum: [],
      workFlowElementTypeEnum: [],
      workFlowElementFormTypeEnum: [],
      fileSrc: null,
      fileId: null,
    }
  },
  async mounted() {
    this.setDefaultValue()
    this.getWorkflowStepTemplateTypeEnum()
    this.getWorkFlowElementTypeEnum()
    this.getWorkFlowElementFormTypeEnum()
    this.dictFindTree()
  },
  methods: {
    dictFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          language: 'zhCN',
          dictType: 'WORK_FLOW_STEP_GROUP',
        },
      }).then(res => {
        const data = res.map(item => {
          return {value: item.id, label: item.value}
        })
        const options = [
            ...data
        ]
        this.config[2].tagProps.options = options
      })
    },
    /**
     * 添加中间元素
     */
    addMiddleElement() {
      const data = {
        content: '请输入文本内容',
        form: 'VIEW',
        isMain: 'NO',
        type: 'TXT',
      }
      this.formData.middleElements.push(data)
    },
    /**
     * 删除中间元素
     * @param index
     */
    delMiddleElement(index) {
      this.formData.middleElements.splice(index, 1)
    },
    addBottomElement() {
      const data = {
        content: '选项',
        form: 'BUTTON',
        isMain: 'NO',
        type: 'MORE',
      }
      this.formData.bottomElements.push(data)
    },
    delBottomElement(index) {
      this.formData.bottomElements.splice(index, 1)
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('fileFrom', 'WORKFLOW_TEMPLATE_STEP_COVER')
      formData.append('file', this.$refs.uploadfile.files[0])
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
      }).then(res => {
        console.log(res)
        this.fileId = res.id
        this.formData.coverImgId = res.id
        this.fileSrc = res.fileHost + '/' + res.objectName
      })
    },
    /**
     * 获取模板步骤类型枚举
     */
    getWorkflowStepTemplateTypeEnum() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateType,
        data: {
          type: 'WorkFlowStepTemplateTypeEnum'
        },
      }).then(res => {
        this.workFlowStepTemplateTypeEnum = res.map(item => {
          return {value: item, label: item}
        })
        this.config[4].tagProps.options = this.workFlowStepTemplateTypeEnum
      })
    },
    /**
     * 获取元素类型枚举
     */
    getWorkFlowElementTypeEnum() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateType,
        data: {
          type: 'WorkFlowElementTypeEnum'
        },
      }).then(res => {
        this.workFlowElementTypeEnum = res.map(item => {
          return {value: item, label: item}
        })
        this.elementSelectConfig.type = this.workFlowElementTypeEnum
      })
    },
    /**
     * 获取元素来源类型枚举
     */
    getWorkFlowElementFormTypeEnum() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateType,
        data: {
          type: 'WorkFlowElementFormTypeEnum'
        },
      }).then(res => {
        this.workFlowElementFormTypeEnum = res.map(item => {
          return {value: item, label: item}
        })
        this.elementSelectConfig.form = this.workFlowElementFormTypeEnum
      })
    },
    // 设置默认值
    setDefaultValue() {
      console.log(this.formData)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.bottomElements = this.formData.bottomElements.map(item => {
            return {
              content: item.content,
              form: item.form,
              isMain: item.isMain,
              type: item.type,
            }
          })
          this.formData.middleElements = this.formData.middleElements.map(item => {
            return {
              content: item.content,
              form: item.form,
              isMain: item.isMain,
              type: item.type,
            }
          })
          if(this.tableRow.dictGroup) {
            this.formData.dictGroupId = this.tableRow.dictGroup.id
          }
          this.formData.coverImgId = this.tableRow.coverImgFile?.id
          this.fileId = this.tableRow.coverImgFile?.id
          this.fileSrc = this.tableRow.coverImgFile?this.tableRow.coverImgFile.fileHost + '/' + this.tableRow.coverImgFile.objectName:null
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateEdit,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
.element-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .field {
    width: 78%;
    border: 5px solid #eeeeee;
    background: #eeeeee;
    margin-right: 5px;
    .field-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-input:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .opt {
    i {
      margin-right: 10px;
      cursor: pointer;
      color: $--color-primary;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>
